import React from "react";
import Slider from "react-slick";
import { slickDot } from "../page-demo/script";
import { useTranslation } from "react-i18next";

const WhyTavrix = () => {
    const [t] = useTranslation("global");
    const ReasonsList = [
        {
            images: '02',
            title: t("whyTavrix.title2"),
            description: t("whyTavrix.desc2")
        },
        {
            images: '01',
            title: t("whyTavrix.title1"),
            description: t("whyTavrix.desc1")
        },
        {
            images: '03',
            title: t("whyTavrix.title3"),
            description: t("whyTavrix.desc3")
        }
    ]
    return (
        <div className="rn-blog-area pt--120 pb--140 bg_color--1" id="why-tavrix">
            <div className="container">
                <div className="row align-items-end">
                    <div className="col-lg-6">
                        <div className="section-title text-left">
                            <h2>{t("whyTavrix.title")}</h2>
                        </div>
                    </div>
                </div>
                <div className="row mt--55 mt_sm--30 rn-slick-dot slick-space-gutter--15 slickdot--20 row--0">
                    <div className="col-12">
                        <Slider {...slickDot}>
                            {ReasonsList.map((value, i) => (
                                <div className="blog reasons-style--2" key={i}>
                                    <div className="thumbnail">
                                        <img src={`/assets/images/reasons/reason-${value.images}.jpg`} alt="Reasons Images" />
                                    </div>
                                    <div className="content">
                                        <h4 className="title text-break">{value.title}</h4>
                                        <p className="text-white text-break" style={{hyphens: "auto", WebkitHyphens: "auto"}}>{value.description}</p>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WhyTavrix;