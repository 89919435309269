import React from 'react';
import Header from "../component/header/Header2";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Footer from "../component/footer/FooterTwo";
import { useTranslation } from "react-i18next";

const Karriere = () => {
    const [t] = useTranslation("global");

    return (
        <>
            {/* Start Header Area  */}
            <Header />
            {/* End Header Area  */}

            {/* Start Karriere Page */}
            <div className="ptb--120 bg_color--5">
                <div className="karriere-wrapper">
                    <div className="container">
                        <div className="row row--35">
                            <div className="col-lg-5">
                                <div className="thumbnail text-center">
                                    <img className="w-70" src="/assets/images/career/Karriere.jpg" alt="Karriere" />
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="about-inner inner" style={{ marginTop: "30px" }}>
                                    <div className="section-title">
                                        <p className="description text-justify text-break" style={{ hyphens: "auto", WebkitHyphens: "auto" }}>{t("career.desc")}</p>
                                    </div>
                                </div>
                                <br /><br />
                                <div className="slide-btn text-center">
                                    <a className="rn-button-style--2 btn-primary-color" href="/#contact">
                                        {t("slider.contactUs")}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Karriere Page */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            <Footer />
        </>
    )
}
export default Karriere;
