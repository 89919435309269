// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';

import PageScrollTop from './component/PageScrollTop';

// Home layout
import HomeParticles from './home/HomeParticles';

// Sub pages
import error404 from "./elements/error404";
import ComingSoon from "./elements/ComingSoon";
import Karriere from "./elements/Karriere";
import Impressum from "./elements/Impressum";
import Datenschutzerkl from "./elements/Datenschutzerkl";

import { BrowserRouter, Switch, Route } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

// i18n
import global_en from "./translations/en/global.json";
import global_de from "./translations/de/global.json";
import i18next from "i18next";
import { I18nextProvider } from 'react-i18next';

const savedLang = localStorage.getItem('language') || 'de';

i18next.init({
    interpolation: { escapeValue: false },
    lng: savedLang,
    resources: {
        de: {
            global: global_de
        },
        en: {
            global: global_en
        }
    }
})

class Root extends Component {
    render() {
        return (
            <BrowserRouter basename={'/'}>
                <PageScrollTop>
                    <Switch>
                        <I18nextProvider i18n={i18next}>
                            {/* Homepage  */}
                            <Route exact path={`${process.env.PUBLIC_URL}/`} component={HomeParticles} />

                            {/* Homepage  */}
                            <Route exact path={`${process.env.PUBLIC_URL}/impressum`} component={Impressum} />

                            {/* Homepage  */}
                            <Route exact path={`${process.env.PUBLIC_URL}/datenschutz`} component={Datenschutzerkl} />

                            {/* Blog Seite  */}
                            <Route exact path={`${process.env.PUBLIC_URL}/blog`} component={ComingSoon} />

                            {/* Karriere Seite  */}
                            <Route exact path={`${process.env.PUBLIC_URL}/karriere`} component={Karriere} />

                            {/* 404 Seite  */}
                            <Route path={`${process.env.PUBLIC_URL}/404`} component={error404} />
                        </I18nextProvider>
                    </Switch>
                </PageScrollTop>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root />, document.getElementById('root'));
serviceWorker.register();