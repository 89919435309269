import React, { useState, useEffect } from "react";
import { FiX, FiMenu } from "react-icons/fi";
import Scrollspy from 'react-scrollspy';
import { useTranslation } from "react-i18next";

function Header(props) {
    const [isEnActive, setIsEnActive] = useState(true);
    const [isDeActive, setIsDeActive] = useState(false);

    // eslint-disable-next-line
    const [t, i18n] = useTranslation("global");

    useEffect(() => {
        const savedLang = localStorage.getItem('language');
        if (savedLang) {
            handleLangSwitch(savedLang);
        }
    // eslint-disable-next-line
    }, []);

    const handleLangSwitch = (lang) => {
        if (lang === "en") {
            setIsEnActive(false);
            setIsDeActive(true);
        } else if (lang === "de") {
            setIsEnActive(true);
            setIsDeActive(false);
        }
        i18n.changeLanguage(lang);
        localStorage.setItem('language', lang);
    }

    const menuTrigger = () => {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }

    const CloseMenuTrigger = () => {
        document.querySelector('.header-wrapper').classList.remove('menu-open');
    }

    var elements = document.querySelectorAll('.has-droupdown > a');
    for (var i in elements) {
        if (elements.hasOwnProperty(i)) {
            elements[i].onclick = function () {
                this.parentElement.querySelector('.submenu').classList.toggle("active");
                this.classList.toggle("open");
            }
        }
    }

    return (
        <header className="header-area formobile-menu header--fixed sticky default-color">
            <div className="header-wrapper" id="header-wrapper">
                <div className="header-left">
                    <div className="logo">
                        <a href="/">
                            <img className="logo-2" src="/assets/images/logo/logo_header.png" alt="Tavrix Logo" />
                        </a>
                    </div>
                </div>
                <div className="header-right">
                    <nav className="mainmenunav d-lg-block">
                        <Scrollspy className="mainmenu" items={['home', 'about', 'service', 'contact', 'career', 'blog']} currentClassName="is-current" offset={-200}>
                            <li><a href="/#home" onClick={handleHomeClick}>Home</a></li>
                            <li><a href="/#about" onClick={handleAboutClick}>{t("header.about")}</a></li>
                            <li><a href="/#service" onClick={handleServiceClick}>{t("header.service")}</a></li>
                            <li><a href="/#contact" onClick={handleContactClick}>{t("header.contact")}</a></li>
                            <li><a href="/karriere">{t("header.career")}</a></li>
                            {/* Todo: Blog wieder einblenden sobald verfügbar */}
                            {/* <li><a href="/blog">Blog</a></li> */}
                            <li><a href="tel:+4971112161308">0711 / 121 613 08</a></li>
                            <li><a href="mailto:kontakt@tavrix.de">kontakt@tavrix.de</a></li>
                        </Scrollspy>
                    </nav>
                    <div className="header-btn">
                        <button className={`rn-btn ${isEnActive ? "" : "hide-element"}`} onClick={() => handleLangSwitch("en")}>en</button>
                        <button className={`rn-btn ${isDeActive ? "" : "hide-element"}`} onClick={() => handleLangSwitch("de")}>de</button>
                    </div>
                    {/* Start Hamburger Menu  */}
                    <div className="hamburger-menu d-block custom-hide-at-1300 pl--20">
                        <span onClick={() => menuTrigger()} className="menutrigger text-white"><FiMenu /></span>
                    </div>
                    {/* End Hamburger Menu  */}
                    <div className="close-menu d-block custom-hide-at-1300">
                        <span onClick={() => CloseMenuTrigger()} className="closeTrigger"><FiX /></span>
                    </div>
                </div>
            </div>
        </header>
    );
}

function handleHomeClick(event) {
    event.preventDefault();
    document.getElementById('home').scrollIntoView({ behavior: 'smooth' });
}

function handleAboutClick(event) {
    event.preventDefault();
    document.getElementById('about').scrollIntoView({ behavior: 'smooth' });
}

function handleServiceClick(event) {
    event.preventDefault();
    document.getElementById('service').scrollIntoView({ behavior: 'smooth' });
}

function handleContactClick(event) {
    event.preventDefault();
    document.getElementById('contact').scrollIntoView({ behavior: 'smooth' });
}

export default Header;