import React, { Fragment } from "react";
import CookieConsent from "react-cookie-consent";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import FooterTwo from "../component/footer/FooterTwo";
import Helmet from "../component/common/Helmet";
import Header from "../component/header/Header";
import SliderOne from "../component/slider/SliderOne";
import About from "../elements/About";
import WhyTavrix from "../elements/WhyTavrix";
import Service from "../elements/Service";
import Contact from "../elements/Contact";
import { useTranslation } from "react-i18next";

const HomeParticles = () => {
    var elements = document.querySelectorAll('.has-droupdown > a');
    for (var i in elements) {
        if (elements.hasOwnProperty(i)) {
            elements[i].onclick = function () {
                this.parentElement.querySelector('.submenu').classList.toggle("active");
                this.classList.toggle("open");
            }
        }
    }

    const [t] = useTranslation("global");

    return (
        <Fragment>
            <Helmet pageTitle="Tavrix" />

            <CookieConsent buttonText={t("cookie.buttonText")} style={{ background: "#4169e1" }} buttonStyle={{ background: "#fff" }}>
                {t("cookie.text")}
            </CookieConsent>

            <Header />

            <SliderOne />

            <About />

            <WhyTavrix />

            <Service />

            <Contact />

            <FooterTwo />

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

        </Fragment>
    )

}
export default HomeParticles;