import React from "react";
import { Helmet } from 'react-helmet';
import { useTranslation } from "react-i18next";

const PageHelmet = (props) => {
    const [t] = useTranslation("global");
    return (
        <React.Fragment>
            <Helmet>
                <title>{props.pageTitle}{t("helmet.titleText")}</title>
                <meta name="description" content={t("helmet.metaDescText")} />
            </Helmet>
        </React.Fragment>
    )
}

export default PageHelmet;
