import React from "react";
import TextLoop from "react-text-loop";
import { useTranslation } from "react-i18next";

const SliderOne = (props) => {
    const [t] = useTranslation("global");
    return (
        <div className="slider-activation slider-creative-agency with-particles" id="home">
            <div className="bg_image bg_image--1" data-black-overlay="3">
                <div className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className={"inner text-left"}>
                                    <h1 className="title">{t("slider.title1")}
                                        <span className="mobile-title-break"></span>
                                        <TextLoop>
                                            <span className="title">{t("slider.advisor")}</span>
                                            <span className="title">{t("slider.recruiter")}</span>
                                            <span className="title">{t("slider.partner")}</span>
                                        </TextLoop>
                                        <br />
                                        {t("slider.title2")}
                                    </h1>
                                    <div className="slide-btn text-center">
                                        <a className="rn-button-style--2 btn-primary-color" href='/#contact' onClick={handleContactClick}>
                                            {t("slider.contactUs")}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function handleContactClick(event) {
    event.preventDefault();
    document.getElementById('contact').scrollIntoView({ behavior: 'smooth' });
}

export default SliderOne;