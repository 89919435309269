import React from 'react';
import Header from "../component/header/Header2";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Footer from "../component/footer/FooterTwo";
import { useTranslation } from "react-i18next";

const ComingSoon = () => {
    const [t] = useTranslation("global");

    return (
        <>
            {/* Start Header Area  */}
            <Header />
            {/* End Header Area  */}

            {/* Start Coming Soon Page */}
            <div className="error-page-inner bg_color--4">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner mt-4">
                                <h2 className="title theme-gradient">{t("comingsoon.header")}</h2>
                                <br /><br /><br />
                                <h4 className="sub-title">{t("comingsoon.desc")}</h4>
                                <br />
                                <div className="error-button">
                                    <a className="rn-button-style--2 btn-solid" href="/">{t("comingsoon.hpbutton")}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Coming Soon Page */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            <Footer />
        </>
    )
}
export default ComingSoon;
