import React from 'react';
import { FaXing, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const SocialShare = [
    { Social: <FaLinkedinIn />, link: 'https://www.linkedin.com/company/tavrix/about' },
    { Social: <FaXing />, link: 'https://www.xing.com/pages/tavrix' },
    { Social: <FaInstagram />, link: 'https://www.instagram.com/tavrix.de/' }
]

const FooterTwo = () => {
    const [t] = useTranslation("global");
    return (
        <div className="footer-style-2 ptb--30 bg_image bg_image--1" data-black-overlay="6">
            <div className="wrapper plr--50 plr_sm--20">
                <div className="row align-items-center justify-content-between">
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className="inner">
                            <div className="logo text-center text-sm-left mt_sm--10">
                                <a href="/">
                                    <img className="logo-footer" src="/assets/images/logo/logo_header.png" alt="Tavrix Logo" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className="inner text-center">
                            <ul className="social-share rn-lg-size d-flex justify-content-center liststyle">
                                {SocialShare.map((val, i) => (
                                    <li key={i}><a href={`${val.link}`} target="_blank" rel="noopener noreferrer">{val.Social}</a></li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                        <div className="inner text-lg-right text-center mt_md--20 mt_sm--20">
                            <div className="links">
                                <p><a href='/impressum' className="links">{t("footer.imprint")}</a> || <a href='/datenschutz' className="links">{t("footer.datenschutzerkl")}</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default FooterTwo;