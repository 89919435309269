import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { useTranslation } from "react-i18next";

const Result = () => {
    const [t] = useTranslation("global");
    return (
        <p className="success-message">{t("contact.successMessage")}</p>
    )
}
function ContactForm({ props }) {
    const [t] = useTranslation("global");
    const [result, showresult] = useState(false);

    const validateEmail = (email) => {
        const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return regex.test(email);
    }

    const sendEmail = (e) => {
        e.preventDefault();
        const email = e.target.email.value;
        if (!validateEmail(email)) {
            alert(t("contact.invalidEmailMessage"));
            return;
        }
        emailjs
            .sendForm(
                'service_3a8byrm',
                'template_vwipbaf',
                e.target,
                'VfnSyCjwm7jpGFQrG'
            )
            .then((result) => {
                console.log(result.text);
            },
                (error) => {
                    console.log(error.text);
                }
            );
        e.target.reset();
        showresult(true);
    };

    setTimeout(() => {
        showresult(false);
    }, 5000);

    return (
        <form action="" onSubmit={sendEmail}>
            <div className="rn-form-group">
                <input
                    type="text"
                    name="fullname"
                    placeholder={t("contact.namePlaceholder")}
                    required
                />
            </div>

            <div className="rn-form-group">
                <input
                    type="email"
                    name="email"
                    placeholder={t("contact.emailPlaceholder")}
                    required
                />
            </div>

            <div className="rn-form-group">
                <input
                    type="text"
                    name="phone"
                    placeholder={t("contact.telPlaceholder")}
                    required
                />
            </div>

            <div className="rn-form-group">
                <input
                    type="text"
                    name="subject"
                    placeholder={t("contact.subjectPlaceholder")}
                    required
                />
            </div>

            <div className="rn-form-group">
                <textarea
                    name="message"
                    placeholder={t("contact.messagePlaceholder")}
                    required
                >
                </textarea>
            </div>

            <div className="rn-form-group">
                <button className="rn-button-style--2 btn-solid" type="submit" value="submit" name="submit" id="mc-embedded-subscribe">{t("contact.sendButtonText")}</button>
            </div>

            <div className="rn-form-group">
                {result ? <Result /> : null}
            </div>
        </form>
    )
}
export default ContactForm;
