import React from "react";
import { useTranslation } from "react-i18next";

const About = (props) => {
    const [t] = useTranslation("global");
    return (
        <div className="about-area ptb--120 bg_color--5" id="about">
            <div className="about-wrapper">
                <div className="container">
                    <div className="row row--35 align-items-center">
                        <div className="col-lg-5">
                            <div className="thumbnail">
                                <img className="w-100" src="/assets/images/about/Home_About us.jpg" alt="About Us" />
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="about-inner inner">
                                <div className="section-title">
                                    <h2 className="title">{t("header.about")}</h2>
                                    <p className="description text-justify" style={{hyphens: "auto", WebkitHyphens: "auto"}}>{t("about.desc")}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;