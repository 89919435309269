import React from 'react';
import Header from "../component/header/Header2";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Footer from "../component/footer/FooterTwo";
import { useTranslation } from "react-i18next";

const Impressum = () => {
    const [t] = useTranslation("global");

    return (
        <>
            {/* Start Header Area  */}
            <Header />
            {/* End Header Area  */}

            {/* Start Impressum Page */}
            <div className="ptb--120 bg_color--5">
                <div className="impressum-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="about-inner inner">
                                <div className="section-title">
                                    <h1 className="title">{t("impressum.title")}</h1>
                                    <p className="text text-justify" style={{whiteSpace: "pre-line", hyphens: "auto", WebkitHyphens: "auto"}}>{t("impressum.text")}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Impressum Page */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            <Footer />
        </>
    )
}
export default Impressum;
