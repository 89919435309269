import React from "react";
import ServiceList from "../elements/service/ServiceList";
import { useTranslation } from "react-i18next";

const Service = () => {
    const [t] = useTranslation("global");
    return (
        <div className="service-area creative-service-wrapper ptb--120 bg_color--5" id="service">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title text-left mb--30">
                            <h2>{t("services.title")}</h2>
                            <p>{t("services.desc")}</p>
                        </div>
                    </div>
                </div>
                <div className="row creative-service">
                    <div className="col-lg-12">
                        <ServiceList item="3" column="col-lg-4 col-md-6 col-sm-6 col-12 text-left" />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Service;