import React from "react";
import ContactForm from "./contact/ContactForm";
import { useTranslation } from "react-i18next";

const Contact = () => {
    const [t] = useTranslation("global");
    return (
        <div className="rn-contact-us ptb--120 bg_color--1" id="contact">
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="section-title text-left mb--50">
                                <h2 className="title">{t("contact.title")}</h2>
                                <p className="description">{t("contact.desc")}</p>
                            </div>
                            <div className="form-wrapper">
                                <ContactForm />
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2 center-mt">
                            <div className="thumbnail mb_md--30 mb_sm--30">
                                <img src="/assets/images/bg/kontakt.jpg" alt="Kontakt Bild" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Contact;