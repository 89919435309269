import React from "react";
import { FiCast, FiLayers, FiUsers } from "react-icons/fi";
import { useTranslation } from "react-i18next";

const ServiceThree = (props) => {
    const [t] = useTranslation("global");
    const ServiceList = [
        {
            icon: <FiUsers />,
            title: t("services.title1"),
            description: t("services.desc1")
        },
        {
            icon: <FiLayers />,
            title: t("services.title2"),
            description: t("services.desc2")
        },
        {
            icon: <FiCast />,
            title: t("services.title3"),
            description: t("services.desc3")
        }
    ]
    const { column } = props;
    const ServiceContent = ServiceList.slice(0, props.item);

    return (
        <React.Fragment>
            <div className="row">
                {ServiceContent.map((val, i) => (
                    <div className={`${column}`} key={i}>
                        <div className="service service__style--2">
                            <div className="icon">
                                {val.icon}
                            </div>
                            <div className="content">
                                <h3 className="title">{val.title}</h3>
                                <p style={{hyphens: "auto", WebkitHyphens: "auto"}}>{val.description}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </React.Fragment>
    )
}

export default ServiceThree;
